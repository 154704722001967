import {useEffect, useRef, useState} from 'react'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {InputFieldSet} from '../../../../_metronic/partials/inputs/InputFieldSet'
import {useQueryResponse} from '../../../modules/view/QueryResponseProvider'
import {Dropdown} from 'react-bootstrap'
import styled from 'styled-components'
import {CARD_STATUS} from '../../cards/core/_models'
import {useMemo} from 'react'
import Select from 'react-select'
import SelectInputCustomStyle from '../../../modules/view/styles/SelectInputCustomStyle'

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  &::after {
    display: none;
  }
`

const FilterCompanyTicketsDropdown = ({
  ticketsByCompany,
  setTicketsByCompany,
  loadingTicketsByCompany,
}: any) => {
  const {isLoading} = useQueryResponse()
  const initialFormState: any = {
    status: '',
  }
  const [form, setForm] = useState<any>(initialFormState)
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef: any = useRef(null)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setForm(initialFormState)
    let copy = {...ticketsByCompany}
    copy.filters = {
      status: null,
    }
    setTicketsByCompany(copy)
    loadingTicketsByCompany(1, 5, null)
    setShowDropdown(false)
  }

  const filterData = () => {
    let copy = {...ticketsByCompany}
    copy.filters = {
      status: form.status?.value,
    }
    setTicketsByCompany(copy)
    loadingTicketsByCompany(1, 5, form?.status?.value)
    setShowDropdown(false)
  }

  const statusOptions = useMemo(() => {
    let options: any[] = []

    options.push({value: '', label: 'Todos'})

    Object.values(CARD_STATUS).forEach((status) => {
      if (status.cardType && status.cardType.includes(2))
        options.push({
          value: status.id,
          label: status.label,
        })
    })

    return options
  }, [])

  useEffect(() => {
    let timeoutId: any = null
    function handleClickOutside(event: any) {
      if (dropdownRef?.current && !dropdownRef.current?.contains(event?.target)) {
        //if para ve se onBlur foi fora do dropdown e se todos os calendarios estiverem fechados
        timeoutId = setTimeout(() => {
          //delay pra nao dar bug visual
          setShowDropdown(false)
        }, 150)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [])

  return (
    <>
      <Dropdown ref={dropdownRef} id='dropdown-true' show={showDropdown}>
        <StyledDropdownToggle
          variant='wl-custom-primary-collor'
          id='dropdown-basic'
          className='btn btn-sm'
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <i className='fa-solid fa-filter'></i>
          Filtros
        </StyledDropdownToggle>

        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'>
          <Dropdown.Header className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Opções de Filtros</div>
          </Dropdown.Header>
          <div className='separator border-gray-200'></div>
          <div className='px-7 py-5' data-kt-user-table-filter='form'>
            <div className='mb-7'>
              <InputFieldSet label={'Status'}>
                <Select
                  name='status'
                  options={statusOptions}
                  onChange={(e: any) => {
                    setForm((prevState: any) => ({
                      ...prevState,
                      ['status']: e,
                    }))
                  }}
                  placeholder='Selecionar status'
                  className='fieldset-input'
                  value={form.status}
                  styles={SelectInputCustomStyle}
                />
              </InputFieldSet>
            </div>
          </div>
          <div className='d-flex justify-content-end p-3 mt-2'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-sm btn-wl-custom-secondary-collor fw-bold me-2 px-6'
            >
              Limpar
            </button>
            <button
              type='button'
              disabled={isLoading}
              onClick={filterData}
              className='btn btn-sm btn-wl-custom-primary-collor fw-bold px-6'
            >
              Aplicar
            </button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export {FilterCompanyTicketsDropdown}
